import React from 'react'
import { connect } from 'react-redux';
import { createCampaign, updateCampaign } from '../../actions/campaigns';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import Attachment from './Attachment';

import "react-datepicker/dist/react-datepicker.css";

const domainOptions = [
  { value: 'org.reifam.ausbildung', label: 'org.reifam.ausbildung' },
  { value: 'org.reifam.admin', label: 'org.reifam.admin' },
  { value: 'com.familienort.my', label: 'com.familienort.my' },
];

const intervalOptions = [
  { value: 'once', label: 'once' },
  { value: 'daily', label: 'daily' },
  { value: 'weekly', label: 'weekly' },
  { value: 'monthly', label: 'monthly' },
  { value: 'yearly', label: 'yearly' },
];

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: props.campaign ? props.campaign.subject : '',
      text: props.campaign ? props.campaign.text : '',
      domain: props.campaign ? props.campaign.domain : '',
      audio: null,
      image: null,
      published_at: props.campaign ? new Date(props.campaign.published_at) : new Date(),
      sender_id: props.campaign ? props.campaign.sender_id : '',
      interval: props.campaign ? props.campaign.interval : 'once',
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { campaign } = this.props;

    campaign ?
      this.props.dispatch(updateCampaign(campaign.uuid, this.state)) :
      this.props.dispatch(createCampaign(this.state));
  }

  render() {
    return (
      <form
        className="border w-full rounded-md overflow-hidden p-5 shadow mb-6 grid grid-cols-2 gap-4"
        onSubmit={this.handleSubmit.bind(this)}>

        <div className="col-span-2">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Subject
          </label>
          <input
            type="text"
            value={this.state.subject}
            onChange={(e) => { this.setState({ subject: e.target.value })}}
            className="my-2 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Sender ID
          </label>
          <input
            type="text"
            value={this.state.sender_id}
            onChange={(e) => { this.setState({ sender_id: e.target.value })}}
            className="my-2 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Domain
          </label>
          <Select
            className="text-xs"
            options={domainOptions}
            value={domainOptions.find((d) => d.value === this.state.domain )}
            isClearable={false}
            onChange={(s) => this.setState({ domain: s.value })} />
        </div>

        <div className="col-span-2">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Text
          </label>
          <textarea
            value={this.state.text}
            onChange={(e) => { this.setState({ text: e.target.value })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none">
          </textarea>
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Publish date
          </label>
          <DatePicker
            showTimeSelect
            dateFormat="dd.MM.yyy HH:mm"
            className="my-2 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
            onChange={(d) => this.setState({ published_at: d })}
            selected={this.state.published_at}/>
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Interval
          </label>
          <Select
            className="text-xs"
            options={intervalOptions}
            value={intervalOptions.find((d) => d.value === this.state.interval )}
            isClearable={false}
            onChange={(s) => this.setState({ interval: s.value })} />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Audio
          </label>
          <input
            type="file"
            onChange={(e) => { this.setState({ audio: e.target.files[0] })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Image
          </label>
          <input
            type="file"
            onChange={(e) => { this.setState({ image: e.target.files[0] })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        {
          this.props.campaign &&
            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Attachments
              </label>
              {
                (this.props.campaign.attachments || []).map((a) => (
                  <Attachment key={a.uuid} attachment={a} />
                ))
              }
            </div>
        }

        <div className="col-span-2">
          <button
            type="submit"
            className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
            Send
          </button>
        </div>
      </form>
    );
  }
}

export default connect()(Form);
